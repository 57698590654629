import React, { 
  useState, 
  useEffect } from 'react';
import axios from 'axios'
import logo from '../assets/img/logo_no_text.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/lookAndFeel/customLook.css';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from "react-router-dom";
import {
  ChevronLeft,
  BriefcaseFill,
  GlobeAmericas,
  PuzzleFill,
  BatteryHalf,
  CalendarDate,
  ArrowCounterclockwise,
  Flower1,
  Linkedin,
  Facebook,
  EnvelopeArrowUp,
  BatteryFull
} from "react-bootstrap-icons";
import { 
  FacebookShareButton, 
LinkedinShareButton
} from 'react-share';


function Home() {
  const [projectsById, setProjectsById] = useState([]);
  const [projectIMG, setProjectIMG] = useState([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(true);
  const id = urlParams.get('id') ?  urlParams.get('id') : 1
  const pag = urlParams.get('pag') ? urlParams.get('pag') : 'aldereca'
  // const client = urlParams.get('client')
  const urlIMG = 'https://projects.soventix.com.do/img/img_projects/'
  const urlAPI = 'https://qr.vhlaurencio.com/'
  const linkToShare = "https://projects.soventix.com.do/?pag="+pag+"&id="+id

  const navigate = useNavigate();

  useEffect(() => {
        setIsLoading(true);
        axios
          .get(urlAPI + "api/Projects/" + id)
          .then((response) => {
            console.log("Response: ", response.data.imgProject);
            setProjectIMG(response.data.imgProject);
            setProjectsById(response.data)
            setIsLoading(false);
          //  console.log('In the state', projectIMG)
          })
          .catch((err) => console.log(err));
        // )
       


  }, []);

const myNav = () => {
  console.log('Pag = '+ pag)
  console.log('id = '+ id)
  return (
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
          <ChevronLeft onClick={()=>navigate('/#')} size={20} color={'#FFF'} />
            <Navbar.Brand href="#home" className='boxLogo'> <img src={logo} className="top-img" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
              <div className={"rowToMenu"}>
              <GlobeAmericas size={30} style={{ marginRight:10}} /><Nav.Link className='item-nav' href="https://soventix.com.do">Ir a la web</Nav.Link>
              </div>

              <div className={"rowToMenu"}>  
              <BriefcaseFill size={30} style={{ marginRight:10}} /><Nav.Link className='item-nav' onClick={()=>navigate('/Projects?id='+id+'&pag='+pag)}>Projectos</Nav.Link>
              </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
  );
}

document.title = "Soventix | "+projectsById.name
// npm install react-router-dom
  return (
    <div>
      {myNav()}

      {isLoading ? (
        // <p>Loading</p>
        <>
          <div
            className={`skeleton`}
            style={{ width: "100%", height: 250 }}
          ></div>
          <div
            className="title skeleton"
            style={{
              height: 40,
              backgroundColor: "#CCC",
              marginTop: 10,
              borderRadius: 5,
              marginRight: 10,
              marginLeft: 10,
            }}
          ></div>
          <div
            className="title skeleton"
            style={{
              height: 20,
              backgroundColor: "#CCC",
              marginTop: 10,
              borderRadius: 5,
              marginRight: 100,
              marginLeft: 10,
            }}
          ></div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: 30,
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            <div
              className="title skeleton"
              style={{
                borderRadius: 10,
                width: 20,
                height: 20,
                marginRight: 10,
              }}
            ></div>
            <div
              className="title skeleton"
              style={{
                height: 20,
                width: 150,
                backgroundColor: "#CCC",
                borderRadius: 5,
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: 30,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              className="title skeleton"
              style={{
                borderRadius: 10,
                width: 20,
                height: 20,
                marginRight: 10,
              }}
            ></div>
            <div
              className="title skeleton"
              style={{
                height: 20,
                width: 200,
                backgroundColor: "#CCC",
                borderRadius: 5,
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: 30,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              className="title skeleton"
              style={{
                borderRadius: 10,
                width: 20,
                height: 20,
                marginRight: 10,
              }}
            ></div>
            <div
              className="title skeleton"
              style={{
                height: 20,
                width: 210,
                backgroundColor: "#CCC",
                borderRadius: 5,
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: 30,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <div
              className="title skeleton"
              style={{
                borderRadius: 10,
                width: 20,
                height: 20,
                marginRight: 10,
              }}
            ></div>
            <div
              className="title skeleton"
              style={{
                height: 20,
                width: "85%",
                backgroundColor: "#CCC",
                borderRadius: 5,
                marginRigth: 10,
              }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: 30,
              marginTop: 10,
              marginBottom: 30,
            }}
          >
            <div
              className="title skeleton"
              style={{
                borderRadius: 10,
                width: 20,
                height: 20,
                marginRight: 10,
              }}
            ></div>
            <div
              className="title skeleton"
              style={{
                height: 20,
                width: "85%",
                backgroundColor: "#CCC",
                borderRadius: 5,
                marginRigth: 10,
              }}
            ></div>
          </div>
        </>
      ) : (
        <>
          <Carousel>
            {projectIMG?.map((img, i) => {
              return (
                <Carousel.Item key={i}>
                  <img
                    src={urlIMG + pag + "/" + img.url}
                    className="d-block w-100"
                    alt="..."
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>

          <div>
            <div className="conten_box">
              <h1>{projectsById.name}</h1>

              <p className='ini'>{projectsById.description}</p>
              <ul className="list_props">
                <span className="d-flex flex-row group_li">
                  <PuzzleFill
                    size={18}
                    style={{ marginRight: 10, marginTop: 5 }}
                  />
                  <li> Modelo: {projectsById.model}</li>
                </span>

                <span className="d-flex flex-row group_li">
                  <BatteryFull
                    size={18}
                    style={{ marginRight: 10, marginTop: 5 }}
                  />
                  <li> Capacidad: {projectsById.capacity} kWp </li>
                </span>

                <span className="d-flex flex-row group_li">
                  <BatteryHalf
                    size={24}
                    style={{ marginRight: 10, marginTop: 3 }}
                  />
                  <li>
                    Generaci&oacute;n de electricidad: {projectsById.generation}{" "}
                    kWh/Kwp/año
                  </li>
                </span>

                <span className="d-flex flex-row group_li">
                  <CalendarDate
                    size={18}
                    style={{ marginRight: 10, marginTop: 3 }}
                  />
                  <li> Completado: {projectsById.complete}</li>
                </span>

                <span className="d-flex flex-row group_li">
                  <ArrowCounterclockwise
                    size={20}
                    style={{ marginRight: 10, marginTop: 5 }}
                  />
                  <li>
                    Ahorro de emisiones de CO2(Kg de CO2): {projectsById.saving}
                  </li>
                </span>

                <span className="d-flex flex-row group_li">
                  <Flower1
                    size={20}
                    style={{ marginRight: 10, marginTop: 5 }}
                  />
                  <li>
                    Equivalencia de arboles plantados anualmente:{" "}
                    {projectsById.equivalence}
                  </li>
                </span>
              </ul>
              <p>
                Nuestras propuestas de inversión incluyen una detallada
                proyección de su retorno. ¿Quisiera analizar esa posibilidad
                para su empresa? Estamos disponibles para ayudarle a visualizar
                su proyecto.
              </p>
            </div>

            <div className="conten_box d-flex flex-row justify-content-evenly">
              <div>
                <p>
                  <LinkedinShareButton url={linkToShare}>
                    <Linkedin size={18} style={{ marginRight: 30 }} />
                  </LinkedinShareButton>
                  <FacebookShareButton url={linkToShare}>
                    <Facebook size={18} style={{ marginRight: 10 }} />
                  </FacebookShareButton>
                  ¡Comparte!
                </p>
              </div>
              <div>
                <a href="https://soventix.com.do/contact/?lang=en">
                  <p>
                    <EnvelopeArrowUp size={18} style={{ marginRight: 10 }} />
                    ¡Contáctanos!
                  </p>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="conten_box footer_green">
        <p>©2024 Soventix, Todos los derechos reservados</p>
      </div>
    </div>
  );
}

export default Home;
