import React, { 
  useState, 
  useEffect } from 'react';
import axios from 'axios'
import logo from '../assets/img/logo_no_text.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/lookAndFeel/customLook.css';
import { useNavigate } from "react-router-dom";
import { ChevronLeft, BriefcaseFill, GlobeAmericas } from 'react-bootstrap-icons';

function Projects() {
  const [projects, setProjects] = useState([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [isLoading, setIsLoading] = useState(false);
  const id = urlParams.get('id') ?  urlParams.get('id') : 1
  const pag = urlParams.get('pag') ? urlParams.get('pag') : 'aldereca'
  // const client = urlParams.get('client')
  const urlIMG = 'https://projects.soventix.com.do/img/img_projects/'
  const urlAPI = 'https://qr.vhlaurencio.com/'
  const skeleton = [1,2,3,4,5,6,7,8];
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Soventix | Projects";
        setIsLoading(true);
        axios
          .get(urlAPI + "api/Projects")
          .then((response) => {
            console.log("Response: ", response.data);
            setProjects(response.data);
            setIsLoading(false);
          //  console.log('In the state', projectIMG)
          })
          .catch((err) => console.log(err));
        // )
    


  }, []);

const myNav = () => {
  console.log('Pag = '+ pag)
  console.log('id = '+ id)
  return (
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
          
          <ChevronLeft onClick={()=>navigate('/?pag='+pag+'&id='+id)} size={20} />
            <Navbar.Brand href="#home" className='boxLogo'> <img src={logo} className="top-img" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
             
              <div className={"rowToMenu"}>
                <GlobeAmericas size={30} style={{ marginRight:10}} /><Nav.Link className='item-nav' href="https://soventix.com.do"> Ir a la web</Nav.Link>
              </div>

              <div className={"rowToMenu"}>
                <BriefcaseFill size={30} style={{ marginRight:10}} /> <Nav.Link className='item-nav' href="#"> Projectos</Nav.Link>
              </div>
             
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
  );
}

// npm install react-router-dom
  return (
  
      <div>
        {myNav()}
       
        <div className="conten_box proy">
       
        {
            isLoading ? 
            skeleton.map((item, index)=>(
              <div className="d-flex flex-row align-items-center row_proy no_border" key={index}>
                <div style={{ width: '100%', paddingTop: 20 }}>
                  <div className='title skeleton' style={{ height: 20, backgroundColor: '#CCC', marginBottom: 10, borderRadius: 5 }}></div>
                  <div className='descriptioon 1 skeleton' style={{ height: 15, backgroundColor: '#CCC', borderRadius: 3 }}></div>
                  {/* <div className='descriptioon 1 skeleton' style={{ height: 15, backgroundColor: '#CCC', borderRadius: 5 }}></div> */}
                  <div></div>
                </div>
              </div>
            ))
      
        :
        
            projects.map((item, index) => (
              // <a href={"./?pag="+item.url+'&id='+item.id} key={index}>
                 <a  onClick={()=>navigate("/?pag="+item.url+'&id='+item.id)} key={index}>
                <div className="d-flex flex-row align-items-center row_proy">
                  <div style={{width:'95%'}}>
                    <h2>{item.name}</h2>
                    <p>{item.description}</p>
                  </div>
                  <i className="bi bi-chevron-right flecha"></i>
                </div></a>
            ))
          }
          </div>

      <div className="conten_box footer_green">
        <p>©2024 Soventix, Todos los derechos reservados</p>
      </div>

      </div>
  
  );
}

export default Projects;
